export default {
    id: 'ID',
    name: 'Name',
    gender: 'Gender',
    parentsName: 'Parents Name',
    therapist: 'Therapist Name',
    age: 'Age',
    studentList: 'Student List',
    students: 'Students',
    addStudents: 'Add New Student',
    upcommingReview: 'Upcoming Review',
    manageStudents: {
        age:'Age',
        title: 'Add New Student',
        studnetsDetails: 'Students Details',
        fullName: 'Full Name',
        enterfullName: 'Enter Full Name',
        dob: 'Date of Birth',
        gender: 'Gender',
        genderPlaceholder: 'Select Gender',
        male: 'Male',
        female: 'Female',
        others: 'Others',
        nativeLanguage: 'Nativate Language',
        disability: 'Disability',
        disabilityPlaceholder: 'Select Disability or not',
        yes: 'Yes',
        no: 'No',
        disabilityType: 'Disability Type',
        disabilityTypePlaceholder: 'Select Disability Type',
        Physicy_Disability :'Physicy Disability',
        Visual_Disability :'Visual Disability',
        Hearing_Disability :'Hearing Disability',
        Speech_Related : 'Speech Related Disability',
        Disability : 'Functional Limitation',
        Deafblindness : 'Deafblindness',
        Intellectual_Disability :'Intellectual Disability',
        Autism :'Autism',
        Hemophilia :'Hemophilia',
        Multiple_Disabilities : 'Multiple Disabilities',
        OTHERS: 'OTHERS',
        uploadPhoto: 'Upload Photo',
        parentsDetails: 'Select Parents',
        therapistDetails: 'Select Therapist',
        schoolDetails: 'Select School',
        address: 'Address',
        province: 'Province',
        district: 'District',
        locallevel: 'Local Level',
        localAddress: 'Local Address',
        tempProvince: 'Province',
        tempDistrict: 'District',
        tempLocalLevel: 'Local Level',
        tempLocalAddress: 'Local Address',
        serviceArea: 'Service Area',
        school: 'School',
        home: 'Home',
        community:'Community',
        cancel: 'Cancel',
        save: 'Save',
        sid: 'Student IEMIS ID',
        roll: 'Roll No',
        section: 'Section',
        level: 'Level',
        NEPALI: 'Nepali',
        Maithili: 'Maithili',
        Bhojpuri:'Bhojpuri',
        'Tharu':'Tharu',
        'Tamang':'Tamang',
        'Nepal Bhasa':'Nepal Bhasa',
        Magar:'Magar',
        Doteli:'Doteli',
        Limbu:'Limbu',
        Gurung:' Gurung',
        Rai:'Rai',
        Chepang:'Chepang',
        OTHERSL:'OTHERS',
        category:'Any Remarks?',
        specialDisability:'Functional Limitations',
        contact:'Contact',
        disabilityDetail:'Additional Details of Disability',
        IEMISNumber:'IEMIS Number',
        disabilityId:'Disability ID',
        'Profound Disability (Red Color ID)':'Profound Disability (Red Color ID)',
        'Severe Disability (Blue Color ID)':'Severe Disability (Blue Color ID)',
       'Moderate Disability (Yellow Color ID)':' Moderate Disability (Yellow Color ID)',
        'Mild Disability (White Color ID)':'Mild Disability (White Color ID)',
        'NO ID':'NO ID',

        ///
        address2:'Temporary Address'


    },
    transfer:{
        heading:'Transfer Student',
        role:'Transfer student role : ',
        user:'Transfer To',
        transferStudent:'Transfer Student',
        close:'Close'
    }
}
