import React, {useEffect, useState} from 'react';
import {
    Box,
    Center,
    CircularProgress,
    Flex,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {IoIosPeople} from "react-icons/io";
import {MdPages} from "react-icons/md";
import {FaSchool} from "react-icons/fa";
import {RiParentFill} from "react-icons/ri";

import {GiDoctorFace} from "react-icons/gi";

import {PieChart, Pie, Cell} from 'recharts';
import UpCommingReviews from "../../components/UpCommingReviews";
import {useNavigate} from "react-router-dom";


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#E91E63', '#9C27B0', '#F8BBD0','#80DEEA'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>);
};


export default function HomePage() {

    const [dashboardData, setDashBoardData] = useState();
    const [loading, setLoading] = useState(true);
    const [pieChartDataByGender, setPieChartDataByGender] = useState([])
    const [pieChartDataTeacherByGender, setPieChartDataTeacherByGender] = useState([])
    const [pieChartDataTherapistByGender, setPieChartDataTherapistByGender] = useState([])
    const [countByDisabilityType, setCountByDisabilityType] = useState([])
    const [countByDisabilityId, setCountByDisabilityId] = useState([])

    useEffect(() => {
        onGetDashBoardData();
    }, [true])

    const onGetDashBoardData = () => {
        axios.post('/dashboard', {
        }).then((res) => {
            let dataGender = [];

            dataGender.push({
                "name": "Male", "value": res.data.studentData.totalBoys?? 0
            });
            dataGender.push({
                "name": "Female", "value": res.data.studentData.totalGirls?? 0
            });
            dataGender.push({
                "name": "Others", "value": res.data.studentData.totalOthersGender ?? 0
            });
            setPieChartDataByGender(dataGender);
            let dataTeacherGender = [];
            dataTeacherGender.push({
                "name": "Male", "value": res.data.teacherData.totalMaleTeacher?? 0
            });
            dataTeacherGender.push({
                "name": "Female", "value": res.data.teacherData.totalFemaleTeacher?? 0
            });
            dataTeacherGender.push({
                "name": "Others", "value": res.data.teacherData.totalOthersTeacher?? 0
            });
            setPieChartDataTeacherByGender(dataTeacherGender);
            let dataTherapistGender = [];
            dataTherapistGender.push({
                "name": "Male", "value": res.data.teacherData.totalMaleTherapist?? 0
            });
            dataTherapistGender.push({
                "name": "Female", "value": res.data.teacherData.totalFemaleTherapist?? 0
            });
            dataTherapistGender.push({
                "name": "Others", "value": res.data.teacherData.totalOthersTherapist?? 0
            });
            setPieChartDataTherapistByGender(dataTherapistGender);

            if (res.data.countByDisabilityType) {
                let disData = [];
                disData.push({
                    "name": "Speech Related", "value": res.data.countByDisabilityType['Speech Related']?? 0
                });
                disData.push({
                    "name": "Visual Disability", "value": res.data.countByDisabilityType['Visual Disability']?? 0
                });
                disData.push({
                    "name": "Disability", "value": res.data.countByDisabilityType['Disability']?? 0
                });
                disData.push({
                    "name": "Autism", "value": res.data.countByDisabilityType['Autism']?? 0
                });
                disData.push({
                    "name": "Physicy Disability", "value": res.data.countByDisabilityType['Physicy Disability']?? 0
                });
                disData.push({
                    "name": "Multiple Disability", "value": res.data.countByDisabilityType['Multiple Disability']?? 0
                });
                disData.push({
                    "name": "Intellectual Disability",
                    "value": res.data.countByDisabilityType['Intellectual Disability']?? 0
                });
                disData.push({
                    "name": "Hearing Disability", "value": res.data.countByDisabilityType['Hearing Disability']?? 0
                });
                setCountByDisabilityType(disData);
            }
            if(res.data.countByDisabilityId){
                let disData = [];
                disData.push({
                    "name": "Profound Disability (Red Color ID)", "value": res.data.countByDisabilityId['Profound Disability (Red Color ID)']?? 0
                });disData.push({
                    "name": "Moderate Disability (Yellow Color ID)", "value": res.data.countByDisabilityId['Moderate Disability (Yellow Color ID)']?? 0
                });disData.push({
                    "name": "Mild Disability (White Color ID)", "value": res.data.countByDisabilityId['Mild Disability (White Color ID)']?? 0
                });disData.push({
                    "name": "Severe Disability (Blue Color ID)", "value": res.data.countByDisabilityId['Severe Disability (Blue Color ID)']
                });
                setCountByDisabilityId(disData);
            }


            setDashBoardData(res.data);
            setLoading(false);
        });
    }
    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    const colors = useColorModeValue(['blue.50', 'green.50', 'yellow.50',], ['blue.900', 'green.900', 'yellow.900',],)
    const [tabIndex, setTabIndex] = useState(0)
    const bg = colors[tabIndex]
    if (loading) {
        return <Center><CircularProgress isIndeterminate/></Center>
    }
    return (

        <Box>


            <Flex flexWrap={'wrap'}>
                <Box mr='12px' mt={'12px'} boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'blue.100'} p={'12px'}
                     borderRadius={'12px'} color={'blue.500'} onClick={()=>navigate('/my_students')} cursor={'pointer'} _hover={{background:'blue.200'}}>
                    <Flex alignItems={'center'}>
                        <Text fontSize={'22px'} fontWeight={'900'} mr={'12px'}>{t('studentLocal:students')}</Text>
                        <IoIosPeople size={'50px'}/>
                    </Flex>
                    <Text fontSize={'40px'} fontWeight={'900'}
                          textAlign={'center'}>{dashboardData.studentData.totalStudents}</Text>
                </Box>
                <Box mr='16px' mt={'12px'} boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'green.100'}
                     color={'green.500'} p={'12px'} borderRadius={'12px'} >
                    <Flex alignItems={'center'}>
                        <Text fontSize={'22px'} fontWeight={'900'} mr={'12px'}>{t('common:plans')}</Text>
                        <MdPages size={'50px'} mr={'12px'}/>
                    </Flex>
                    <Text fontSize={'40px'} fontWeight={'900'}
                          textAlign={'center'}>{dashboardData.teacherData.totalPlans}</Text>
                </Box>
                <Box mr='16px' mt={'12px'} boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'orange.100'}
                     color={'orange.900'} p={'12px'} borderRadius={'12px'} onClick={()=>navigate('/school')} cursor={'pointer'} _hover={{background:'orange.200'}}>
                    <Flex alignItems={'center'}>
                        <Text fontSize={'22px'} fontWeight={'900'} mr={'12px'}>{t('school:title')}</Text>
                        <FaSchool size={'50px'} mr={'12px'}/>
                    </Flex>
                    <Text fontSize={'40px'} fontWeight={'900'}
                          textAlign={'center'}>{dashboardData.studentData.totalSchool}</Text>
                </Box>
                <Box mr='16px' mt={'12px'} boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'purple.100'}
                     color={'purple.900'} p={'12px'} borderRadius={'12px'} onClick={()=>navigate('/user?role=PARENT')} cursor={'pointer'} _hover={{background:'purple.200'}}>
                    <Flex alignItems={'center'}>
                        <Text fontSize={'22px'} fontWeight={'900'} mr={'12px'}>{t('users:PARENT')}</Text>
                        <RiParentFill size={'50px'} mr={'12px'}/>
                    </Flex>
                    <Text fontSize={'40px'} fontWeight={'900'}
                          textAlign={'center'}>{dashboardData.teacherData.totalParent}</Text>
                </Box>
                <Box mr='16px' mt={'12px'} boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'pink.100'}
                     color={'pink.900'} p={'12px'} borderRadius={'12px'} onClick={()=>navigate('/user?roleTHERAPIST')} cursor={'pointer'} _hover={{background:'pink.200'}}>
                    <Flex alignItems={'center'}>
                        <Text fontSize={'22px'} fontWeight={'900'} mr={'12px'}>{t('users:THERAPIST')}</Text>
                        <GiDoctorFace size={'50px'} mr={'12px'}/>
                    </Flex>
                    <Text fontSize={'40px'} fontWeight={'900'}
                          textAlign={'center'}>{dashboardData.teacherData.totalTherapist}</Text>
                </Box>
            </Flex>
            <Flex flexWrap={'wrap'} mt={'16px'}>
                {dashboardData.upcomingReviews && (

                <Box w='40%' mr='16px' boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'white'} p={'12px'} borderRadius={'12px'}>
                    <UpCommingReviews upcommingreviews={dashboardData.upcomingReviews}/>
                </Box>
                )}
                <Box w='40%' boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'white'} p={'12px'} borderRadius={'12px'}
                     mr={'16px'} textAlign={'center'}>
                    <Text fontSize={'30px'} fontWeight={'900'} textAlign={'center'}>Number of Student by Gender</Text>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={pieChartDataByGender.filter(entry => entry.value !== 0)}

                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={140}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {pieChartDataByGender.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
                        </Pie>
                    </PieChart>
                    <Flex flexWrap={'wrap'}>
                        {pieChartDataByGender.map((i, index) => <Flex mr={'8px'} alignItems={'center'}>
                            <Box background={COLORS[index % COLORS.length]} width={'20px'} height={'10px'}
                                 mr={'4px'}></Box>
                            <Text fontWeight={'900'}>{`${i.name} : ${i.value}`}</Text>
                        </Flex>)}
                    </Flex>

                </Box>

            </Flex>
            <Flex mt={'16px'}>
                <Box w='40%' mr='16px'  boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'white'} p={'12px'} borderRadius={'12px'}>
                    <Text fontSize={'30px'} fontWeight={'900'} textAlign={'center'}>Number of Student by Disability
                        Type</Text>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={countByDisabilityType.filter(entry => entry.value !== 0)}

                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={140}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {countByDisabilityType.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
                        </Pie>
                    </PieChart>
                    <Flex flexWrap={'wrap'}>
                        {countByDisabilityType.map((i, index) => <Flex mr={'8px'} alignItems={'center'}>
                            <Box background={COLORS[index % COLORS.length]} width={'20px'} height={'10px'}
                                 mr={'4px'}></Box>
                            <Text fontWeight={'900'}>{`${i.name} : ${i.value}`}</Text>
                        </Flex>)}
                    </Flex>

                </Box>
                <Box w='40%' boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'white'} p={'12px'} borderRadius={'12px'}>
                    <Text fontSize={'30px'} fontWeight={'900'} textAlign={'center'}>Number of Student by Disability ID
                        Type</Text>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={countByDisabilityId.filter(entry => entry.value !== 0)}

                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={140}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {countByDisabilityId.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
                        </Pie>
                    </PieChart>
                    <Flex flexWrap={'wrap'}>
                        {countByDisabilityId.map((i, index) => <Flex mr={'8px'} alignItems={'center'}>
                            <Box background={COLORS[index % COLORS.length]} width={'20px'} height={'10px'}
                                 mr={'4px'}></Box>
                            <Text fontWeight={'900'}>{`${i.name} : ${i.value}`}</Text>
                        </Flex>)}
                    </Flex>

                </Box>


            </Flex>
            <Flex mt={'16px'}>



            <Box w='40%' boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'white'} p={'12px'} borderRadius={'12px'}
                 mr={'16px'} textAlign={'center'}>
                <Text fontSize={'30px'} fontWeight={'900'} textAlign={'center'}>Number of Teacher by Gender</Text>
                <PieChart width={400} height={400}>
                    <Pie
                        data={pieChartDataTeacherByGender.filter(entry => entry.value !== 0)}

                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={140}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {pieChartDataTeacherByGender.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
                    </Pie>
                </PieChart>
                <Flex flexWrap={'wrap'}>
                    {pieChartDataTeacherByGender.map((i, index) => <Flex mr={'8px'} alignItems={'center'}>
                        <Box background={COLORS[index % COLORS.length]} width={'20px'} height={'10px'}
                             mr={'4px'}></Box>
                        <Text fontWeight={'900'}>{`${i.name} : ${i.value}`}</Text>
                    </Flex>)}
                </Flex>

            </Box>

                <Box w='40%' boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'white'} p={'12px'} borderRadius={'12px'}
                     mr={'16px'} textAlign={'center'}>
                    <Text fontSize={'30px'} fontWeight={'900'} textAlign={'center'}>Number of Therapist by Gender</Text>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={pieChartDataTherapistByGender.filter(entry => entry.value !== 0)}

                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={140}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {pieChartDataTherapistByGender.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
                        </Pie>
                    </PieChart>
                    <Flex flexWrap={'wrap'}>
                        {pieChartDataTherapistByGender.map((i, index) => <Flex mr={'8px'} alignItems={'center'}>
                            <Box background={COLORS[index % COLORS.length]} width={'20px'} height={'10px'}
                                 mr={'4px'}></Box>
                            <Text fontWeight={'900'}>{`${i.name} : ${i.value}`}</Text>
                        </Flex>)}
                    </Flex>

                </Box>

            </Flex>
        </Box>


    )
}
